function validateForm(formData, language, translations) {
  const errors = {};

  if (!formData.nombres.trim()) {
    errors.nombres = translations[language].contacts.validarForm.nombre.obligatorio;
  }

  if (!formData.correo.trim()) {
    errors.correo = translations[language].contacts.validarForm.correo.obligatorio;
  } else if (!/\S+@\S+\.\S+/.test(formData.correo)) {
    errors.correo = translations[language].contacts.validarForm.correo.valido;
  }

  if (formData.celular.trim()) {
    if (!/^[\d\s()+-]+$/.test(formData.celular.trim())) {
      errors.celular = translations[language].contacts.validarForm.celular.caracteres;
    } else if (formData.celular.trim().length < 10) {
      errors.celular = translations[language].contacts.validarForm.celular.minimo_caracteres;
    }
  }


  if (!formData.mensaje.trim()) {
    errors.mensaje = translations[language].contacts.validarForm.mensaje.obligatorio;
  } else if (formData.mensaje.trim().length < 10) {
    errors.mensaje = translations[language].contacts.validarForm.mensaje.minimo_caracteres;
  }

  return errors;
}

export default validateForm;