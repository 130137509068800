import './easyUse.css'
import generar from '../../assets2/EasyUse/crear.png'
import enviar from '../../assets2/EasyUse/enviar.png'
import entrar from '../../assets2/EasyUse/entrar.png'
import trazar from '../../assets2/EasyUse/trazabilidad.png'
import video from '../../assets2/EasyUse/easy2.mp4'

import { useLanguage } from '../../contexts/LanguageContext';

export const EasyUse = () => {

    const { language, translations } = useLanguage();

    const pasos = [
        {
            imgSrc: generar,
            title: translations[language].home.facil.generar,
            text: translations[language].home.facil.generartxt
        },
        {
            imgSrc: enviar,
            title: translations[language].home.facil.enviar,
            text: translations[language].home.facil.enviartxt
        },
        {
            imgSrc: entrar,
            title: translations[language].home.facil.entrar,
            text: translations[language].home.facil.entrartxt
        },
        {
            imgSrc: trazar,
            title: translations[language].home.facil.trazabilidad,
            text: translations[language].home.facil.trazabilidadtxt
        }
    ];

    return (
        <div className="easy-use">
            <h1>{translations[language].home.facil.titulo}</h1>
            <div className="contenedor">
                <div className="proceso">
                    {pasos.map((paso, index) => (
                        <div className="pasos-proceso">
                            <div className="icono-proceso">
                                <img src={paso.imgSrc} loading="lazy" alt={paso.title} />
                            </div>
                            <div className="texto-procesos">
                                <h3>{paso.title}</h3>
                                <p>{paso.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="video-proceso">
                    <video autoPlay loop muted playsInline>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
}