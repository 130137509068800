import { useState, useEffect } from 'react';
import Slider from "react-slick";
import './clientsSection.css'

import { useLanguage } from '../../contexts/LanguageContext';

import event1 from '../../assets2/Funtions/clientes/event1.jpeg'
import event2 from '../../assets2/Funtions/clientes/event2.jpeg'
import event3 from '../../assets2/Funtions/clientes/event3.jpeg'
import event4 from '../../assets2/Funtions/clientes/event4.jpeg'

import tenisClub  from '../../assets2/Funtions/clientes/tenis-club.png'
import bancoGuayaquil  from '../../assets2/Funtions/clientes/banco-guayaquil.png'
import pacificaCenter  from '../../assets2/Funtions/clientes/pacifica-center.png'
import ulvr  from '../../assets2/Funtions/clientes/ulvr.png'
import casaGrande  from '../../assets2/Funtions/clientes/casa-grande.png'
import arcosPlaza  from '../../assets2/Funtions/clientes/arcos-plaza.png'
import solca  from '../../assets2/Funtions/clientes/solca.png'
import puntaMocoli  from '../../assets2/Funtions/clientes/punta-mocoli.png'
import almax  from '../../assets2/Funtions/clientes/almax.png'

// https://react-slick.neostack.com/d/ocs/get-started



const eventSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
};

export const ClientsSection = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { language, translations } = useLanguage();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  return (
    <div id="clients-section" className='clients-section pn-bg-orange'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>

            <div className='row events-wrapper'>
              <div className='col-12'>
                <div className='row'>
                  <p className='header-title'>{translations[language].home.cliente.eventos.titulo}</p>
                </div>
                <div className='col-12 col-md-10 offset-md-1 mt-4'>
                  <div className='row'>
                    <Slider {...((windowSize.innerWidth > 576) ? ({ ...eventSettings }) : ({ ...eventSettings, slidesToShow: 1 }))}>
                      <img className='img-fluid p-2' src={event1} alt="Imagen Evento 1" loading="lazy" />
                      <img className='img-fluid p-2' src={event2}  alt="Imagen Evento 2" loading="lazy" />
                      <img className='img-fluid p-2' src={event3} alt="Imagen Evento 3" loading="lazy" />
                      <img className='img-fluid p-2' src={event4}  alt="Imagen Evento 4" loading="lazy" />
                    </Slider>
                  </div>
                </div>

              </div>
            </div>

            <div className='row clients-wrapper'>
              <div className='col-12'>
                <div className='row'>
                  <p className='header-title'>{translations[language].home.cliente.clientes.titulo}</p>
                </div>
                <div className='row align-items-center'>

                  <div className='col-10 offset-1 mt-4'>
                    <div className='row align-items-center justify-content-center'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={tenisClub} alt="Logo de Tenis club" loading="lazy"></img>
                      </div>
                      <div className='client-logo max'>
                        <img className='img-fluid' src={bancoGuayaquil} alt="Logo del Banco Guayaquil" loading="lazy"></img>
                      </div>
                      <div className='client-logo'>
                        <img className='img-fluid' src={pacificaCenter} alt="Logo de Pacífica Center" loading="lazy"></img>
                      </div>
                      <div className='client-logo'>
                        <img className='img-fluid' src={ulvr} alt="Logo de Universidad Vicente Rocafuerte" loading="lazy"></img>
                      </div>
                      <div className='client-logo max'>
                        <img className='img-fluid' src={casaGrande} alt="Logo de Universidad Casa Grande" loading="lazy"></img>
                      </div>
                    </div>
                  </div>

                  <div className='col-10 offset-1'>
                    <div className='row align-items-center justify-content-center'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={arcosPlaza} alt="Logo de Arcos Plaza" loading="lazy"></img>
                      </div>
                      <div className='client-logo'>
                        <img className='img-fluid' src={solca} alt="Logo de Solca" loading="lazy"></img>
                      </div>
                      <div className='client-logo'>
                        <img className='img-fluid' src={puntaMocoli} alt="Logo de Punta Mocolí" loading="lazy"></img>
                      </div>
                      <div className='client-logo max'>
                        <img className='img-fluid' src={almax} alt="Logo de Almax 3" loading="lazy"></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  )
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}