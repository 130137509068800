import "./cualidad.css";

export const Cualidad = ({icono,cualidad, texto, imagen}) => {
  return (
    <div className="main_cualidad">
        <img className="img_cualidad" src={imagen} alt='imagen de cualidad'></img>
        <h4>{cualidad}</h4>
        <p>{texto}</p>
        <img className="icon_cualidad" src={icono} alt='icono de cualidad'></img>
    </div>
  )
}