import React from 'react';
import FormEmail from './FormEmail'
import Cards from './Cards'
import './styles-contact/contact.css'

import { useLanguage } from '../../contexts/LanguageContext';

function Contacts() {

  const { language, translations } = useLanguage();

  return (
    <div id='contacto' className='main-contact'>
      <h1>{translations[language].contacts.titulo}</h1>
      <div className='content-contact'>
        <FormEmail />
        <hr className="hr-contact-form"></hr>
        <Cards />
      </div>
    </div>
  );
}

export default Contacts;