import imgLogo from '../../assets2/Download/pinlet.png';
import txtLogo from '../../assets2/Download/textoLogoPinlet.png';
import iconDescarga from '../../assets2/Download/iconDescarga.png'
import logoPlayStore from '../../assets2/Download/google.png'
import logoApple from '../../assets2/Download/apple.png';
import logoHuawei from '../../assets2/Download/huawei.png';
import fondoCelulares from '../../assets2/Download/fondo_celulares.webp';
import './Download.css'

import { useLanguage } from '../../contexts/LanguageContext';

function Download() {

    const { language, translations } = useLanguage();
    
    return (
        <div className="main_descargar">
            <div className='opciones'></div>
            <div className='fondo_celulares'>
                <img src={fondoCelulares} alt='fondo de celulares usando pinlet'>
                </img>
            </div>
            <div className="contenedor">
                <div className="marca">
                    <img className='img' alt='logo de pinlet' src={imgLogo}>
                    </img>
                    <img className='texto' alt='logo texto de pinlet' src={txtLogo}>
                    </img>
                    <h3>{translations[language].download.subtitulo[1]}<b>{translations[language].download.subtitulo[2]}</b>{translations[language].download.subtitulo[3]}</h3>
                </div>
                <div className='hr'></div>
                <div className="descarga_aqui">
                    <div className='encabezado'>
                        <p>{translations[language].download.canal}</p>
                    </div>
                    <div className='enlaces'>
                        <div className="btnDescarga playStore">
                            <a href="https://play.google.com/store/apps/details?id=com.vionsolutions.qrticket&hl=es" target="_blank" rel="noopener noreferrer">
                                <img src={logoPlayStore} alt='logo de play store'></img>
                                <p>Play Store</p>
                            </a>
                        </div>
                        <div className="btnDescarga appStore">
                            <a href="https://apps.apple.com/app/qr-ticket/id1533524491" target="_blank" rel="noopener noreferrer">
                                <img src={logoApple} alt='logo de app store'></img>
                                <p>App Store</p>
                            </a>
                        </div>
                        <div className="btnDescarga huawei">
                            <a href="https://appgallery.huawei.com/app/C108879605" target="_blank" rel="noopener noreferrer">
                                <img src={logoHuawei} alt='logo de huawei'></img>
                                <p>Huawei</p>
                            </a>
                        </div>
                        <div className="btnDescarga otros">
                            <a href='https://storage.googleapis.com/versiones/app-release.apk' rel="noopener noreferrer">
                                <img src={iconDescarga} alt='icono de descarga'></img>
                                <p>{translations[language].download.otros}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Download;