import './footer2.css';
import logoCompleto from '../../assets2/pinlet-completo.png'
import instagram from '../../assets2/footer/instagram-logo.png';
import facebook from '../../assets2/footer/facebook-logo.png';
import linkedin from '../../assets2/footer/linkedin-logo.png';
import playStore from '../../assets2/footer/play-store-logo.png'
import appStore from '../../assets2/footer/app-store-logo.png'
import huawei from '../../assets2/footer/huawei-logo.png'
import apk from '../../assets2/Download/iconDescarga.png'

import { useLanguage } from '../../contexts/LanguageContext';

export const Footer2 = () => {

	const { language, translations } = useLanguage();

	return (
		<div className='main-footer'>
			<div className="contenedor">
				<div className='nosotros'>
					<div className='marca'>
						<img src={logoCompleto} alt='logo pinlet' loading="lazy"></img>
					</div>
					<div className='politicas'>
						<h3>{translations[language].footer.nosotros}</h3>
						<a href="https://pinlet.app/Politicas.pdf" target="_blank" rel="noreferrer">{translations[language].footer.politicas}</a>
					</div>
				</div>
				<div className='contenido'>
					<div className='contactos'>
						<div className='contacto'>
							<h3>{translations[language].footer.contacto}</h3>
							<hr></hr>
							<p>{translations[language].footer.email}: <a href="mailto:info@pinlet.net">info@pinlet.net</a></p>
							<p>{translations[language].footer.telefono}: <a href="tel:+593939858060">+593939858060</a></p>
						</div>
						<div className='Cliente'>
							<h3>{translations[language].footer.servicio}</h3>
							<hr></hr>
							<p>{translations[language].footer.email}: <a href="mailto:pinletdev@pinlet.net">pinletdev@pinlet.net</a></p>
							<p>{translations[language].footer.telefono}: <a href="tel:+593968253695">+593968253695</a></p>
						</div>
					</div>
					<div className='enlaces'>
						<div className='siguenos'>
							<h3>{translations[language].footer.siguenos}</h3>
							<hr></hr>
							<div className='redes'>
								<a href="https://www.instagram.com/pinletec/" target="_blank" rel="noopener noreferrer">
									<img src={instagram} alt='icono de descarga' loading="lazy"></img>
								</a>
								<a href="https://www.facebook.com/profile.php?id=61552649222436" target="_blank" rel="noopener noreferrer">
									<img src={facebook} alt='icono de descarga' loading="lazy"></img>
								</a>
								<a href="https://www.linkedin.com/company/pinlet-ecuador/" target="_blank" rel="noopener noreferrer">
									<img src={linkedin} alt='icono de descarga' loading="lazy"></img>
								</a>
							</div>
						</div>
						<div className='descarga'>
							<h3>{translations[language].footer.descarga}</h3>
							<hr></hr>
							<div className='opciones'>
								<a href="https://play.google.com/store/apps/details?id=com.vionsolutions.qrticket&hl=es" target="_blank" rel="noopener noreferrer">
									<img src={playStore} alt='icono de Play Store' loading="lazy"></img>
								</a>
								<a href="https://apps.apple.com/app/qr-ticket/id1533524491" target="_blank" rel="noopener noreferrer">
									<img src={appStore} alt='icono de App store' loading="lazy"></img>
								</a>
								<a href="https://appgallery.huawei.com/app/C108879605" target="_blank" rel="noopener noreferrer">
									<img src={huawei} alt='icono de Huawei' loading="lazy"></img>
								</a>
								<a href='https://storage.googleapis.com/versiones/app-release.apk' rel="noopener noreferrer">
									<img src={apk} alt='icono de descarga' loading="lazy"></img>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr className='separador-bajo'></hr>
			<div className='copyright'>
				<p>{translations[language].footer.derechos}</p>
			</div>
		</div>
	)
}
