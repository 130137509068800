import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToSection = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const checkIfElementLoaded = () => {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
          requestAnimationFrame(checkIfElementLoaded);
        }
      };
      
      // Delay the initial check to allow for the page to start rendering
      setTimeout(() => {
        requestAnimationFrame(checkIfElementLoaded);
      }, 100);
    }
  }, [pathname, hash]);

  return null;
};