//Donde usar
//Eventos

import './funcionalidades.css'
import { WhenUse } from "../../sections/WhenUse/whenUse";
import { AdminSection2 } from "../../sections/AdminSection2/adminSection2";

function Funcionalidades() {
    return (
        <div className="main_funcionalidades">
            <WhenUse />
            <AdminSection2 />
        </div>
    )
}

export default Funcionalidades;