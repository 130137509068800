import { useState } from 'react';
import ConsumoVideos from './ConsumoVideos';
import './questions.css';
import { useLanguage } from '../../contexts/LanguageContext';

export const Questions = () => {

    const { language, translations } = useLanguage();

    const [selectedVideoId, setSelectedVideoId] = useState(7);
    const [videoDataCache, setVideoDataCache] = useState({});

    const preguntas = [
        { pregunta: translations[language].help.preguntas[1], idVideo: 7 },
        { pregunta: translations[language].help.preguntas[2], idVideo: 8 },
        { pregunta: translations[language].help.preguntas[3], idVideo: 10 },
        { pregunta: translations[language].help.preguntas[4], idVideo: 11 },
        { pregunta: translations[language].help.preguntas[5], idVideo: 12 },
        { pregunta: translations[language].help.preguntas[6], idVideo: 13 },
        { pregunta: translations[language].help.preguntas[7], idVideo: 14 },
        { pregunta: translations[language].help.preguntas[8], idVideo: 15 },
        { pregunta: translations[language].help.preguntas[9], idVideo: 16 },
        { pregunta: translations[language].help.preguntas[10], idVideo: 17 },
        { pregunta: translations[language].help.preguntas[11], idVideo: 18 },
        { pregunta: translations[language].help.preguntas[12], idVideo: 19 }
    ];

    const handleQuestionClick = (idVideo) => {
        setSelectedVideoId(idVideo);
    };

    const handleVideoDataFetched = (idVideo, data) => {
        setVideoDataCache(prevCache => ({ ...prevCache, [idVideo]: data }));
    };

    return (
        <div id='videos' className='questions'>
        
            <div className='contenedor'>
                <div className="preguntas">
                    <h1>{translations[language].help.titulo}</h1>
                    <ul>
                        {preguntas.map((pregunta, index) => (
                            <li
                                key={index}
                                onClick={() => handleQuestionClick(pregunta.idVideo)}
                                className={selectedVideoId === pregunta.idVideo ? 'active' : ''}
                                style={{ cursor: 'pointer' }}
                            >
                                <p>{pregunta.pregunta}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='videos'>
                    <div className='respuesta'>
                        <ConsumoVideos 
                            key={selectedVideoId} 
                            idVideo={selectedVideoId} 
                            cachedData={videoDataCache[selectedVideoId]}
                            onVideoDataFetched={handleVideoDataFetched}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Questions;