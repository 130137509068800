import { Link } from 'react-scroll';
import './bitacoraMovil.css'
import marca from '../../../assets2/textoLogoPinlet.png';
import { Cualidad } from '../../../components/CualidadProducto/Cualidad'
import iconMovil from '../../../assets2/productos/bitacoraMovil/iconMovil.png'
import iconIntuitivo from '../../../assets2/productos/bitacoraMovil/iconIntuitivo.png'
import iconAccesible from '../../../assets2/productos/bitacoraMovil/iconAccesible.png'
import movil from '../../../assets2/productos/bitacoraMovil/movil.png'
import intuitivo from '../../../assets2/productos/bitacoraMovil/intuitivo.png'
import accesible from '../../../assets2/productos/bitacoraMovil/accesible.png'

//iconos de manual
import iconLogin from '../../../assets2/productos/bitacoraMovil/iconLogin.png'
import iconRegistro from '../../../assets2/productos/bitacoraMovil/iconRegistro.png'
import iconQR from '../../../assets2/productos/bitacoraMovil/iconQR.png'
import iconIngreso from '../../../assets2/productos/bitacoraMovil/iconIngreso.png'
import iconSalida from '../../../assets2/productos/bitacoraMovil/iconSalida.png'

//imagenes de manual
import login from '../../../assets2/productos/bitacoraMovil/login.webp'
import funciones from '../../../assets2/productos/bitacoraMovil/funciones.webp'
import propiedades from '../../../assets2/productos/bitacoraMovil/propiedades.webp'
import ingreso from '../../../assets2/productos/bitacoraMovil/ingreso.webp'
import salida from '../../../assets2/productos/bitacoraMovil/salida.webp'
import historial from '../../../assets2/productos/bitacoraMovil/historial.webp'

import { useLanguage } from '../../../contexts/LanguageContext';

function BitacoraMovil() {

    const { language, translations } = useLanguage();

    const srcCualidades = [
        { icono: iconMovil, imagen: movil },
        { icono: iconIntuitivo, imagen: intuitivo },
        { icono: iconAccesible, imagen: accesible }
    ];

    const cualidadesData = srcCualidades.map((item, index) => ({
        icono: item.icono,
        cualidad: translations[language].products.bitacoraMovilSeccion.cualidadesSeccion[index + 1].titulo,
        imagen: item.imagen,
        texto: translations[language].products.bitacoraMovilSeccion.cualidadesSeccion[index + 1].texto
    }));

    const pasos = [
        {
            icono: iconLogin,
            titulo: translations[language].products.bitacoraMovilSeccion.pasos[1].titulo,
            info: translations[language].products.bitacoraMovilSeccion.pasos[1].info,
            imagen: login
        },
        {
            icono: iconRegistro,
            titulo: translations[language].products.bitacoraMovilSeccion.pasos[2].titulo,
            info:
                <ul>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[2].info[1]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[2].info[2]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[2].info[3]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[2].info[4]}</p></li>
                </ul>,
            imagen:historial
        },
        {
            icono: iconQR,
            titulo: translations[language].products.bitacoraMovilSeccion.pasos[3].titulo,
            info:
                <ul>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[3].info[1]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[3].info[2]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[3].info[3]}</p></li>
                </ul>,
            imagen: funciones
            
        },
        {
            icono: iconQR,
            titulo: translations[language].products.bitacoraMovilSeccion.pasos[4].titulo,
            info:
                <ul>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[4].info[1]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[4].info[2]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[4].info[3]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[4].info[4]}</p></li>
                </ul>,
            imagen:propiedades
        },
        {
            icono: iconIngreso,
            titulo: translations[language].products.bitacoraMovilSeccion.pasos[5].titulo,
            info:
                <ul>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[5].info[1]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[5].info[2]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[5].info[3]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[5].info[4]}</p></li>
                </ul>,
            imagen: ingreso
        },
        {
            icono: iconSalida,
            titulo: translations[language].products.bitacoraMovilSeccion.pasos[6].titulo,
            info:
                <ul>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[6].info[1]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[6].info[2]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[6].info[3]}</p></li>
                    <li><p>{translations[language].products.bitacoraMovilSeccion.pasos[6].info[4]}</p></li>
                </ul>,
                imagen: salida
        }
    ];

    return (
        <div className="main_bitacoraMovil">
            <div className='titulo'>
                <img src={marca} alt='texto logo pinlet'></img>
                <h3>{translations[language].products.bitacoraMovil}</h3>
            </div>

            <div className='informacion'>
                <p className='descripcion'>
                    {translations[language].products.bitacoraMovilSeccion.descripcion}
                </p>
                {/* PUNTOS DE NAVEGACIÓN---------------------------------------------------------------------------------
                <div className='puntos_navegacion'>
                    {pasos.map((_, index) => (
                        <Link key={`link_${index}`} to={`bitacoraMovil_paso_${index + 1}`} smooth duration={100} offset={-125}>{index + 1}</Link>
                    ))}
                    <Link key={`link_${pasos.length+1}`} to={`bitacoraMovil_paso_${pasos.length + 1}`} smooth duration={100} offset={-125}>{pasos.length + 1}</Link>
                </div>*/}
                <div className='manual'>
                    {pasos.map((paso, index) => (
                        <div id={`bitacoraMovil_paso_${index + 1}`} className='pasos'>
                            <div className='numero'>
                                <p>{index + 1}</p>
                                <img src={paso.icono} alt='icono del paso' />
                            </div>
                            <div className='contenido'>
                                <div className='texto'>
                                    <h2>{paso.titulo}</h2>
                                    {paso.info}
                                </div>
                                <div className='imagenes'>
                                    <img src={paso.imagen} alt='visualización del paso' />
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                    ))}
                </div>
                <div id={`bitacoraMovil_paso_${pasos.length + 1}`} className='cualidades'>
                    {cualidadesData.map((cualidadItem) => (
                        <Cualidad key={cualidadItem.cualidad} {...cualidadItem} />
                    ))}
                </div>
            </div>
        </div >
    )
}

export default BitacoraMovil;