import './videos.css'
import { Questions } from "../../sections/FAP/Questions"

function Videos() {
    return (
        <div className="main_Videos">

            <Questions />
        </div>
    )
}

export default Videos;