import './App.css';
import { Home } from './pages/Home';
import { LanguageProvider } from './contexts/LanguageContext';

function App() {
  return (
    <LanguageProvider>
      <div className="App container-fluid">
        <Home />
      </div>
    </LanguageProvider>
  );
}

export default App;
