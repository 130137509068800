import axios from "axios";
import { useState, useEffect } from "react";
import cargando from '../../assets2/FAQ/cargando.gif'

function ConsumoVideos({ idVideo, cachedData, onVideoDataFetched }) {
    const apiUrl = 'https://pinlet.app/apiRest6/corp/v1/getAyuda';
    const [videoData, setVideoData] = useState(cachedData || {
        titulo: '',
        descripcion: '',
        imagen: '',
        pregunta: ''
    });

    const [isLoading, setIsLoading] = useState(!cachedData);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const apiKey = process.env.REACT_APP_API_KEY_VIDEOS;
                const headers = { 'Authorization': `Bearer ${apiKey}` };
                const response = await axios.post(apiUrl, { id_ayuda: idVideo }, { headers });
                console.log("Response:", response.data);

                const fetchedData = {
                    titulo: response.data.titulo,
                    descripcion: response.data.descripcion,
                    imagen: response.data.imagen,
                    pregunta: response.data.pregunta
                };

                setVideoData(fetchedData);
                onVideoDataFetched(idVideo, fetchedData);

            } catch (err) {
                console.error("Error:", err);
                setError(err.message || 'Ocurrió un error al obtener los datos del video');
            } finally {
                setIsLoading(false);
            }
        };

        if (!cachedData) {
            fetchData();
        } else {
            setVideoData(cachedData);
            setIsLoading(false);
        }
    }, [idVideo, cachedData, onVideoDataFetched]);

    const src = `https://storage.googleapis.com/pinlet/Ayudas/${videoData.imagen}`;

    if (isLoading) {
        return <div className="video_ayuda">
            <div className="cargando">
                <img src={cargando}>
                </img>
            </div>
        </div>;
    }

    return (
        <div className="video_ayuda">
            <video controls>
                <source src={src} type="video/mp4" />
            </video>
            {error && <p>{error}</p>}
        </div>
    );
}

export default ConsumoVideos;