
import React, { useState, useEffect } from "react";
import './styles-contact/formemail.css'
import validateForm from "./js-contact/validarForm";
import axios from 'axios';

import { useLanguage } from '../../contexts/LanguageContext';
import iconCorrecto from '../../assets2/Contactos/correcto.png'
import iconError from '../../assets2/Contactos/error.png'

function FormEmail() {

  const { language, translations } = useLanguage();

  const apiUrl = 'https://pinlet.app/apiRest6/corp/v1/Contactanos_web';

  const [formData, setFormData] = useState({
    nombres: '',
    correo: '',
    celular: '',
    asunto: '',
    mensaje: '',
    usuario_creacion: "Pinlet"
  });

  const [notificacion, setNotificacion] = useState({ message: "", type: "success", visible: "novisible" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    let timer;
    if (notificacion.visible === 'visible') {
      timer = setTimeout(() => {
        setNotificacion(prev => ({ ...prev, visible: 'novisible' }));
      }, 3500);
    }
    return () => clearTimeout(timer);
  }, [notificacion.visible]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(formData,language, translations);

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      try {
        const apiKey = process.env.REACT_APP_API_KEY_CONTACTS;
        const headers = { 'Authorization': `Bearer ${apiKey}` };
        const response = await axios.post(apiUrl, formData, { headers });
        console.log(response.data);
        if (response.data.status === 'Se envió correctamente') {

          setNotificacion({
            message: translations[language].contacts.formulario.confirmacion.correcto,
            type: 'success',
            visible: "visible"
          });

          setFormData({
            nombres: '',
            correo: '',
            celular: '',
            asunto: '',
            mensaje: '',
            usuario_creacion: 'Pinlet'
          });
        } else {
          setNotificacion({
            message:  translations[language].contacts.formulario.confirmacion.incorrecto,
            type: 'error',
            visible: "visible"
          });
        }
        setErrors({});
      } catch (error) {
        console.error(error); // Maneja errores
      }
    }
  };

  return (
    <div className='main-form-email'>
      <form onSubmit={handleSubmit}>
        <div className="text-form-Email">
          <h3>{translations[language].contacts.formulario.pregunta}</h3>
          <p>{translations[language].contacts.formulario.preguntatxt}</p>
        </div>
        <div className="contenedor-input-contacto">
          <div>
            <label htmlFor="nombres">{translations[language].contacts.formulario.nombres}</label>
            <input
              id="nombres"
              type="text"
              name="nombres"
              value={formData.nombres}
              onChange={handleChange}
            />
            {errors.nombres && <p className="errorForm">{errors.nombres}</p>}
          </div>

          <div className="medios-contactos">
            <div className="medio-correo">
              <label htmlFor="correo">{translations[language].contacts.formulario.correo}</label>
              <input
                placeholder="ejemplo@gmail.com"
                id="correo"
                type="email"
                name="correo"
                value={formData.correo}
                onChange={handleChange}
              />
              {errors.correo && <p className="errorForm">{errors.correo}</p>}
            </div>
            <div className="medio-telefono">
              <label htmlFor="celular">{translations[language].contacts.formulario.numero}</label>
              <input
                id="celular"
                type="tel"
                name="celular"
                value={formData.celular}
                onChange={handleChange}
              />
              {errors.celular && <p className="errorForm">{errors.celular}</p>}
            </div>

          </div>

          <div>
            <label htmlFor="asunto">{translations[language].contacts.formulario.asunto}</label>
            <input
              id="asunto"
              type="text"
              name="asunto"
              value={formData.asunto}
              onChange={handleChange}
            />
            {errors.asunto && <p className="errorForm">{errors.asunto}</p>}
          </div>

          <div>
            <label htmlFor="mensaje">{translations[language].contacts.formulario.mensaje}</label>
            <textarea
              id="mensaje"
              name="mensaje"
              value={formData.mensaje}
              onChange={handleChange}
            />
            {errors.mensaje && <p className="errorForm">{errors.mensaje}</p>}
          </div>

          <button className="btn-shine" type="submit">{translations[language].contacts.formulario.enviar}</button>
        </div>

        <div className={`notificacion ${notificacion.visible}`}>
          <div className={`${notificacion.type}`}>
            <img
              src={notificacion.type === 'success' ? iconCorrecto : iconError}
              alt={notificacion.type === 'success' ? 'Correcto' : 'Error'}
            />
            <p>{notificacion.message}</p>
          </div>

        </div>

      </form>
    </div>
  );
}

export default FormEmail;