import React, { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import usuario from '../../assets2/Navegation/usuario.png';
import LanguageSwitcher from '../languageSwitcher/LanguageSwitcher';
import { useLanguage } from '../../contexts/LanguageContext';
import './navbarItem.css';
import logo from '../../assets2/pinlet-completo.png';

export const NavbarItem = () => {
  const { language, translations } = useLanguage();
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleNavClick = () => {
    setExpanded(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleProductClick = () => {
    setDropdownOpen(false);
    const screenWidth = window.innerWidth;
    if (screenWidth <= 991) {
      setExpanded(!expanded);
    }
  };

  const handleDocumentClick = useCallback((e) => {
    if (dropdownOpen && !e.target.closest('.dropdown')) {
      setDropdownOpen(false);
    }
  }, [dropdownOpen]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [handleDocumentClick]);

  return (
    <Navbar expanded={expanded} expand="lg" className="main_nav sticky-top navbar-dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Logo" height='56px' width="auto" loading="lazy" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end gap-5'>
          <Nav>
            <Nav.Link as={Link} to="/functions" className='pn-link' onClick={handleNavClick}>
              {translations[language].header.inicio}
            </Nav.Link>
            <Nav.Link as={Link} to="/funcionalidades" className='pn-link' onClick={handleNavClick}>
              {translations[language].header.funciones}
            </Nav.Link>
            <Dropdown as={Nav.Item} show={dropdownOpen}>
              <Dropdown.Toggle as={Nav.Link} id="dropdown-basic" className='pn-link' onClick={toggleDropdown} >
                {translations[language].header.productos.titulo}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/productos/movil" onClick={handleProductClick} aria-controls="basic-navbar-nav">
                  {translations[language].header.productos.movil}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/productos/bitacora" onClick={handleProductClick} aria-controls="basic-navbar-nav">
                  {translations[language].header.productos.bitacora}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/productos/bitacoraMovil" onClick={handleProductClick} aria-controls="basic-navbar-nav">
                  {translations[language].header.productos.bitacoraMovil}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link as={Link} to="/Videos" className='pn-link' onClick={handleNavClick}>
              {translations[language].header.videos}
            </Nav.Link>
            <Nav.Link as={Link} to="/descargar" className='pn-link' onClick={handleNavClick}>
              {translations[language].header.descarga}
            </Nav.Link>
            <Nav.Link as={Link} to="/contacts" className='pn-link' onClick={handleNavClick}>
              {translations[language].header.contactos}
            </Nav.Link>
          </Nav>
          <Nav  className='nav_right'>
            <Nav.Link href='https://pinlet.app/pinlet-admin/auth/login' className='pn-link login'>
              <img src={usuario} className='icon-login' alt='icono de login' />
              <p>{translations[language].header.login}</p>
            </Nav.Link>
            <LanguageSwitcher />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};