import { ResidentItem } from "../../components/ResidentItem/residentItem";
import "./residentsSection.css";

import { useLanguage } from '../../contexts/LanguageContext';

import Recurso45 from '../../assets2/Funtions/residentes/Recurso45-min.webp'
import Recurso46 from '../../assets2/Funtions/residentes/Recurso46.png'
import Recurso47 from '../../assets2/Funtions/residentes/Recurso47.png'
import Recurso48 from '../../assets2/Funtions/residentes/Recurso48.png'
import Recurso49 from '../../assets2/Funtions/residentes/Recurso49.png'

export const ResidentsSection = () => {
  
  const { language, translations } = useLanguage();

  return (
    <div id="residents-section" className="residents-section">
      <div className="container">
        <div className="col-10 offset-1 col-md-12 offset-md-0">
          <div className="row align-items-center">
            <div className="col-12 col-md-5 background">
              <img className="img-fluid" src={Recurso45} alt="Smarthpone con app en pantalla de residentes" loading="lazy"></img>
            </div>
            <div className="col-12 col-md-7" style={{marginTop: '3rem'}}>
              <div className="row text-start">
                <p className="pinlet">{translations[language].home.residentes.pinlet}</p>
                <p className="resident">{translations[language].home.residentes.titulo}</p>
                <p className="features">{translations[language].home.residentes.subtitulo}</p>
              </div>
              <div className="row" style={{marginTop: '2rem'}}>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <ResidentItem src={Recurso46} title={translations[language].home.residentes.item1.titulo} description={translations[language].home.residentes.item1.descripcion}
                      ></ResidentItem>
                    </div>
                    <div className="col-6">
                      <ResidentItem src={Recurso47}title={translations[language].home.residentes.item2.titulo} description={translations[language].home.residentes.item2.descripcion}></ResidentItem>
                    </div>
                    <div className="col-6">
                      <ResidentItem src={Recurso48} title={translations[language].home.residentes.item3.titulo} description={translations[language].home.residentes.item3.descripcion}></ResidentItem>
                    </div>
                    <div className="col-6">
                      <ResidentItem src={Recurso49} title={translations[language].home.residentes.item4.titulo} description={translations[language].home.residentes.item4.descripcion}></ResidentItem>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}