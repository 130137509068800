import { useRef, useState, useEffect } from 'react';
import './movil2.css'
import marca from '../../../assets2/textoLogoPinlet.png';
import { Cualidad } from '../../../components/CualidadProducto/Cualidad'
import Slider from "react-slick";
import { Link } from 'react-router-dom';

//imagenes manual
import login from '../../../assets2/productos/movil/login.webp'
import olvidarContrasena from '../../../assets2/productos/movil/olvidarContraseña.webp'
import crearCuenta from '../../../assets2/productos/movil/crearCuenta.webp'
import lugaresRegistrados from '../../../assets2/productos/movil/lugaresRegistrados.webp'
import invitacionesRecibidas from '../../../assets2/productos/movil/invitacionesRecibidas.webp'
import codigo from '../../../assets2/productos/movil/codigo.webp'
import crearInvitacion from '../../../assets2/productos/movil/crearInvitacion.webp'
import reservarServicio from '../../../assets2/productos/movil/reservarServicio.webp'
import anunciosLugar from '../../../assets2/productos/movil/anunciosLugar.webp'
import perfil from '../../../assets2/productos/movil/perfil.webp'

//iconos manual
import iconLogin from '../../../assets2/productos/movil/iconLogin.png'
import iconOlvidarContrasena from '../../../assets2/productos/movil/iconOlvidarContrasena.png'
import iconCrearCuenta from '../../../assets2/productos/movil/iconCrearCuenta.png'
import iconLugaresRegistrados from '../../../assets2/productos/movil/iconLugaresRegistrados.png'
import iconInvitacionesRecibidas from '../../../assets2/productos/movil/iconInvitacionesRecibidas.png'
import iconCodigo from '../../../assets2/productos/movil/iconCodigo.png'
import iconCrearInvitacion from '../../../assets2/productos/movil/iconCrearInvitacion.png'
import iconReservarServicio from '../../../assets2/productos/movil/iconReservarServicio.png'
import iconAnunciosLugar from '../../../assets2/productos/movil/iconAnunciosLugar.png'
import iconPerfil from '../../../assets2/productos/movil/iconPerfil.png'

//imagenes cualidades
import evento from '../../../assets2/productos/movil/evento.webp'
import anuncio from '../../../assets2/productos/movil/anunciar.webp'
import registro from '../../../assets2/productos/movil/registrar.webp'
import invitar from '../../../assets2/productos/movil/invitar.webp'

//iconos cualidades
import iconAnuncio from '../../../assets2/productos/movil/anuncios.png'
import iconInvitar from '../../../assets2/productos/movil/invitaciones.png'
import iconEvento from '../../../assets2/productos/movil/eventos.png'
import iconRegistro from '../../../assets2/productos/movil/registro.png'

import { useLanguage } from '../../../contexts/LanguageContext';

function Movil() {
    const sliderPaso = useRef(null);

    const { language, translations } = useLanguage();

    const [btnSelected, setBtnSelected] = useState(null);

    const handleClickBtnCollapse = (index, id) => {
        setBtnSelected(index)

        sliderPaso.current.slickGoTo(index);
        const element = document.getElementById(id);

        if (element) {
            const offset = 0;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    const srcCualidades = [
        { icono: iconRegistro, imagen: registro },
        { icono: iconInvitar, imagen: invitar },
        { icono: iconEvento, imagen: evento },
        { icono: iconAnuncio, imagen: anuncio }
    ];

    const cualidadesData = srcCualidades.map((item, index) => ({
        icono: item.icono,
        cualidad: translations[language].products.movilSeccion.cualidadesSeccion[index + 1].titulo,
        imagen: item.imagen,
        texto: translations[language].products.movilSeccion.cualidadesSeccion[index + 1].texto
    }));

    const srcManual = [
        { icon: iconLogin, image: login },
        { icon: iconCrearCuenta, image: crearCuenta },
        { icon: iconOlvidarContrasena, image: olvidarContrasena },
        { icon: iconLugaresRegistrados, image: lugaresRegistrados },
        { icon: iconInvitacionesRecibidas, image: invitacionesRecibidas },
        { icon: iconCodigo, image: codigo },
        { icon: iconCrearInvitacion, image: crearInvitacion },
        { icon: iconReservarServicio, image: reservarServicio },
        { icon: iconAnunciosLugar, image: anunciosLugar },
        { icon: iconPerfil, image: perfil }
    ];

    const pasos = srcManual.map((step, index) => ({
        icono: step.icon,
        titulo: translations[language].products.movilSeccion.pasos[index + 1].titulo,
        src: step.image,
        explicacion: translations[language].products.movilSeccion.pasos[index + 1].descripcion
    }));

    const settingsManual = {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        afterChange: (index) => setBtnSelected(index)
    };

    const handleResize = () => {
        if (window.innerWidth < 768) {
            pasos.forEach((_, index) => {
                const element = document.getElementById(`paso${index + 1}`);
                if (element && element.classList.contains('show')) {
                    element.classList.remove('show');
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="main_movil">
            <div className='contenedor'>
                <div className='titulo'>
                    <img src={marca} alt='texlo logo pinlet'></img>
                    <h3>{translations[language].products.movil}</h3>
                </div>
                <div className='informacion'>
                    <p className='descripcion'>
                        {translations[language].products.movilSeccion.descripcion}
                    </p>
                    <div className='explorar'>
                        <div className='btnPasos'>
                            {pasos.map((paso, index) => (
                                <div className='btnCollapseMovil'>
                                    <button key={index} className={`btnPaso ${btnSelected === index ? 'btnSelected' : ''}`} onClick={() => handleClickBtnCollapse(index)} type="button" data-bs-toggle="collapse" data-bs-target={`#paso${index + 1}`}>
                                        <div className='imgBtnPaso'>
                                            <img src={paso.icono} alt='icono de paso' />
                                        </div>
                                        <p className='btnClassPaso'>
                                            {paso.titulo}
                                        </p>
                                    </button>
                                    <div id={`paso${index + 1}`} className='collapse paso'>
                                        <div className='imgFondo'>
                                            <img src={paso.src} alt='visualización del paso'></img>
                                        </div>
                                        <div className='texto'>
                                            <img src={paso.icono} alt='icono de paso' />
                                            <h2>{paso.titulo}</h2>
                                            <p>{paso.explicacion}</p>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                        <div id="movilSlide" className='manual'>
                            <Slider ref={sliderPaso} className='carrusel' {...settingsManual}>
                                {pasos.map((paso, index) => (
                                    <div className='paso'>
                                        <div className='imgFondo'>
                                            <img src={paso.src} alt='visualización del paso'></img>
                                        </div>
                                        <div className='texto'>
                                            <img src={paso.icono} alt='icono de paso' />
                                            <h2>{paso.titulo}</h2>
                                            <p>{paso.explicacion}</p>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className='Titulo'>
                        <h3>{translations[language].products.movilSeccion.cualidades}</h3>
                    </div>
                    <div className='cualidades'>
                        {cualidadesData.map((cualidadItem) => (
                            <Cualidad key={cualidadItem.cualidad} {...cualidadItem} />
                        ))}
                    </div>
                </div>
                <div className='mas_informacion'>
                    <Link to="/Videos" className="btn">
                        {translations[language].products.movilSeccion.informacion}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Movil;