import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import iconEspanol from '../../assets2/Idiomas/espanol.png';
import iconIngles from '../../assets2/Idiomas/ingles.png';
import './languageSwitcher.css';

const LanguageSwitcher = () => {
  const { language, changeLanguage, translations } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div className="language-switcher">
      <button onClick={toggleDropdown} className="idioma-button">
        {language === 'en' ? (
          <>
            <img src={iconIngles} alt="English icon" className="icon" />
            En
          </>
        ) : (
          <>
            <img src={iconEspanol} alt="Spanish icon" className="icon" />
            Es
          </>
        )}
      </button>
      {isOpen && (
        <div className="idioma-menu">
          <button onClick={() => handleLanguageChange('en')} className="idioma-item">
            <img src={iconIngles} alt="English icon" className="icon" />
            {translations[language].languages.english}
          </button>
          <button onClick={() => handleLanguageChange('es')} className="idioma-item">
            <img src={iconEspanol} alt="Spanish icon" className="icon" />
            {translations[language].languages.spanish}
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;