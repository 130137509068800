
import './styles-contact/cards.css'

import { useLanguage } from '../../contexts/LanguageContext';
import contactos from '../../assets2/Contactos/contactos.png'
import cliente from '../../assets2/Contactos/servicio-cliente.png'

function Cards() {

    const { language, translations } = useLanguage();

    return (
        <div className="main-cards">
            <div className="card">
                <div className="img-card">
                    <img src={contactos} alt="icono de telefono"></img>
                </div>
                <div className="content-card">
                    <h2>{translations[language].contacts.tarjetas.pregunta}</h2>
                    <a href="mailto:info@pinlet.net">
                        <p>info@pinlet.net</p></a>
                    <a href="tel:+593939858060">
                        <p>+593-939858060</p></a>
                </div>
            </div>
            <div className="card">
                <div className="img-card">
                    <img src={cliente} alt="icono de correo"></img>
                </div>
                <div className="content-card">
                    <h2>{translations[language].contacts.tarjetas.servicio}</h2>
                    <a href="mailto:pinletdev@pinlet.net">
                        <p>pinletdev@pinlet.net</p> </a>
                    <a href="tel:+593968253695">
                        <p>+593-968253695</p> </a>
                </div>
            </div>
        </div>
    )

}

export default Cards;