import React, { useState } from 'react';
import './bitacora.css'
import marca from '../../../assets2/textoLogoPinlet.png';
import { Cualidad } from '../../../components/CualidadProducto/Cualidad'
//iconos cualidad
import iconHistorial from '../../../assets2/productos/bitacora/historial.png'
import iconAcceso from '../../../assets2/productos/bitacora/acceso.png'
import iconIdentidad from '../../../assets2/productos/bitacora/identidad.png'

//imagenes paso
import cedula from '../../../assets2/productos/bitacora/cedula.webp'
import OCR from '../../../assets2/productos/bitacora/OCR.png'
import residentes from '../../../assets2/productos/bitacora/residentes.png'
import actividades from '../../../assets2/productos/bitacora/actividades.png'
import enviar from '../../../assets2/productos/bitacora/enviar.png'

//imagenes cualidad
import visitantes from '../../../assets2/productos/bitacora/visitantes.jpeg'
import grupales from '../../../assets2/productos/bitacora/grupales.png'
import manuales from '../../../assets2/productos/bitacora/manuales.png'


//imagenes pasos extra
import registro from '../../../assets2/productos/bitacora/registro.png'
import verificacion from '../../../assets2/productos/bitacora/verificacion.png'
import carpeta from '../../../assets2/productos/bitacora/carpeta.png'

//iconos de componente de colapso
import colapso from '../../../assets2/productos/bitacora/colapso.png'
import info from '../../../assets2/productos/bitacora/info.png'

import { useLanguage } from '../../../contexts/LanguageContext';

function Bitacora() {

    const { language, translations } = useLanguage();

    const [clickedIndices, setClickedIndices] = useState([]);

    const handleClick = (index) => {

        setClickedIndices((prevClickedIndices) =>
            prevClickedIndices.includes(index)
                ? prevClickedIndices.filter((i) => i !== index)
                : [...prevClickedIndices, index]
        );
    };

    const imgpasos = [
        cedula,
        OCR,
        residentes,
        actividades,
        enviar
    ]



    const pasos = Array.from({ length: 5 }, (_, index) => {
        const stepIndex = index + 1;
        return {
            titulo: translations[language].products.bitacoraSeccion.pasos[stepIndex].titulo,
            info: translations[language].products.bitacoraSeccion.pasos[stepIndex].info,
            info_extra: translations[language].products.bitacoraSeccion.pasos[stepIndex].info_extra,
            img: imgpasos[index]
        };
    });

    const pasosExtra = [
        {
            id: translations[language].products.bitacoraSeccion.pasosExtra[1].id,
            textoBtn: translations[language].products.bitacoraSeccion.pasosExtra[1].textoBtn,
            textoCollapse: translations[language].products.bitacoraSeccion.pasosExtra[1].textoCollapse,
            contenidoCollapse: <p>{translations[language].products.bitacoraSeccion.pasosExtra[1].contenidoCollapse}</p>,
            extraCollapse: translations[language].products.bitacoraSeccion.pasosExtra[1].extraCollapse,
            imgCollapse: visitantes
        },
        {
            id: translations[language].products.bitacoraSeccion.pasosExtra[2].id,
            textoBtn: translations[language].products.bitacoraSeccion.pasosExtra[2].textoBtn,
            textoCollapse: translations[language].products.bitacoraSeccion.pasosExtra[2].textoCollapse,
            contenidoCollapse: <p>{translations[language].products.bitacoraSeccion.pasosExtra[2].contenidoCollapse}</p>,
            extraCollapse: translations[language].products.bitacoraSeccion.pasosExtra[2].extraCollapse,
            imgCollapse: grupales
        },
        {
            id: translations[language].products.bitacoraSeccion.pasosExtra[3].id,
            textoBtn: translations[language].products.bitacoraSeccion.pasosExtra[3].textoBtn,
            textoCollapse: translations[language].products.bitacoraSeccion.pasosExtra[3].textoCollapse,
            contenidoCollapse: <ul>
                {Object.keys(translations[language].products.bitacoraSeccion.pasosExtra[3].contenidoCollapse).map(key => {
                    const item = translations[language].products.bitacoraSeccion.pasosExtra[3].contenidoCollapse[key];
                    return (
                        <li key={key}>
                            <h6>{item.numero}</h6>
                            {item.texto}
                        </li>
                    );
                })}
            </ul>,
            extraCollapse: "",
            imgCollapse: manuales
        },
    ]

    const srcCualidades = [
        { icono: iconAcceso, imagen: registro },
        { icono: iconIdentidad, imagen: verificacion },
        { icono: iconHistorial, imagen: carpeta }
    ];
    
    const cualidadesData = srcCualidades.map((item, index) => ({
        icono: item.icono,
        cualidad: translations[language].products.bitacoraSeccion.cualidadesSeccion[index + 1].titulo,
        imagen: item.imagen,
        texto: translations[language].products.bitacoraSeccion.cualidadesSeccion[index + 1].texto
    }));

    return (
        <div className="main_bitacora">

            <div className='contenedor'>
                <div className='titulo'>
                    <img src={marca} alt='texto logo pinlet'></img>
                    <h3>{translations[language].products.bitacora}</h3>
                </div>
                <div className='informacion'>
                    <p className='descripcion'>
                        {translations[language].products.bitacoraSeccion.descripcion}
                    </p>
                    <div className='manual'>
                        <div className='pasos'>
                            {pasos.map((paso, index) => (
                                <div key={paso.id || index} className={`paso ${index % 2 === 0 ? 'izquierda' : 'derecha'}`}>
                                    <div className='texto'>
                                        <h3>{paso.titulo}</h3>
                                        <p>{paso.info}</p>
                                        <p className='info_extra'>{paso.info_extra}</p>
                                    </div>
                                    <div className='img_paso'>
                                        <img src={paso.img} alt='paso del manual'></img>
                                    </div>
                                </div>
                            ))}

                            {pasosExtra.map((paso, index) => (
                                <div className='pasos_extra'>
                                    <button class="btnCollapse" type="button" data-bs-toggle="collapse" onClick={() => handleClick(index)} data-bs-target={`#${paso.id}`}>
                                        <p>{paso.textoBtn}</p>
                                        <img
                                            src={colapso}
                                            alt="paso extra del manual"
                                            className={clickedIndices.includes(index) ? 'clicked' : ''}
                                        />
                                    </button>

                                    <div class="collapse paso_extra" id={paso.id}>
                                        <div className='content'>
                                            <div className='textoCollapse'>
                                                <h3>{paso.textoCollapse}</h3>
                                                {paso.contenidoCollapse}

                                                {paso.extraCollapse.trim() ? (
                                                    <span className="d-inline-block" tabIndex="0" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={paso.extraCollapse}>
                                                        <button className="btnInfo" type="button" disabled>
                                                            <img src={info} alt="Info" />
                                                        </button>
                                                        <div className='msgInfoExtra'>
                                                            <h6>{paso.extraCollapse}</h6>
                                                        </div>
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className='imgCollapse'>
                                                <img src={paso.imgCollapse} alt="interfaz de seccion de grupales" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='cualidades'>
                        {cualidadesData.map((cualidadItem) => (
                            <Cualidad key={cualidadItem.cualidad} {...cualidadItem} />
                        ))}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Bitacora;