
import "./adminSection2.css";

import { useLanguage } from '../../contexts/LanguageContext';
import laptop from '../../assets2/Funtions/admin/RecursoLaptop-min.webp'

import iconCedula from '../../assets2/Funtions/admin/Cedula.png'
import iconPlaca from '../../assets2/Funtions/admin/placa.png'
import iconRostro from '../../assets2/Funtions/admin/rostro.png'

import nube from '../../assets2/Funtions/admin/nube.png'

export const AdminSection2 = () => {

  const { language, translations } = useLanguage();

  return (
    <div className="admin-section2">
      <div className="contenedor">
        <div className="info">

          <div className="img_fondo">
            <img src={laptop} />
          </div>

          <div className="detalles">
            <div className="encabezado">
              <h2 className="titulo">{translations[language].functions.admin.pinlet}</h2>
              <h1 className="titulo">{translations[language].functions.admin.titulo}</h1>
              <p>{translations[language].functions.admin.subtitulo}</p>
            </div>

            <div className="puntos">
              <div className="punto">
                <p>{translations[language].functions.admin.puntos[1]}</p>
                <img src={iconCedula}/>
              </div>
              <div className="punto">
                <p>{translations[language].functions.admin.puntos[2]}</p>
                <img src={iconPlaca} />
              </div>
              <div className="punto">
                <p>{translations[language].functions.admin.puntos[3]}</p>
                <img src={iconRostro}/>
              </div>
            </div>
          </div>
        </div>

        <div className="extra">
          <p>{translations[language].functions.admin.texto}</p>
          <img src={nube} />
        </div>

      </div>
    </div>
  )
}