import './initSection.css';
import recurso10es  from '../../assets2/Funtions/Init/Recurso10es.png'
import recurso10en  from '../../assets2/Funtions/Init/Recurso10en.png'

import recurso11es  from '../../assets2/Funtions/Init/Recurso11es.png'
import recurso11en  from '../../assets2/Funtions/Init/Recurso11en.png'

import recurs9 from '../../assets2/Funtions/Init/Recurso9-min.webp'

import { useLanguage } from '../../contexts/LanguageContext';

export const Init = () => {

  const { language, translations } = useLanguage();

  const recurso10 = translations[language].languages.spanish === "Español" ? recurso10es : recurso10en;
  const recurso11 = translations[language].languages.spanish === "Español" ? recurso11es : recurso11en;
  return (
    <div id="inicio" className="inicio fullscreen">
      <div className="row fullscreen">
        <div className="col-12">
          <div className="row align-items-center content">
            <div className="col-12 col-md-3 left">
              <div className="row">
                <div className="col-10 offset-1">
                  <img className="img-fluid" src={recurso10} alt="Pinlet app en smartphones" loading="lazy"></img>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img className="img-fluid" src={recurs9} alt="Pinlet app en smartphones" loading="lazy"></img>
            </div>
            <div className="col-12 col-md-3 right">
            <div className="row">
                <div className="col-10 offset-1 offset-md-0">
                  <img className="img-fluid" src={recurso11} alt="Pinlet app en smartphones" loading="lazy"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}