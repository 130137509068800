import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Functions2 } from "../../sections/Functions2/functions2";
import { ScrollToSection } from "./ScrollToSection";
import { ScrollToTop } from "./ScrollToTop";
import ContactsPage from '../Contact/Contacts';
import DescargarPage from '../Descargar/Download';
import { Footer2 } from "../../sections/Footer2/footer2";
import { NavbarItem } from "../../components/NavbarItem/navbarItem";
import Funcionalidades from "../Funcionalidades/Funcionalidades";
import FloatingWhatsApp from "../../components/FloatingWhatsApp/FloatingWhatsApp";
import Videos from "../Videos/Videos";
import Productos from '../Productos/Productos';


export const Home = () => {
  return (
    <Router>
      <div id="top"></div>
      <div className="row sticky-top">
        <NavbarItem />
      </div>
      <FloatingWhatsApp />
      <ScrollToSection />
      <ScrollToTop />
      <div className="row">
        <Routes>
          <Route path="/" element={<Functions2 />} />
          <Route path="/functions" element={<Functions2 />} />
          <Route path="/funcionalidades" element={<Funcionalidades />} />
          <Route path="/productos/*" element={<Productos />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/descargar" element={<DescargarPage />} />
        </Routes>
        </div>
      <div className="row">
        <Footer2 />
      </div>
    </Router>
  );
};