import './extra.css';
import left from '../../assets2/Extra/izquierda.webp'
import right from '../../assets2/Extra/derecha.webp'
import mid from '../../assets2/Extra/centro.webp'

import { useLanguage } from '../../contexts/LanguageContext';

export const Extra = () => {

  const { language, translations } = useLanguage();

  return (
    <div className="extra-section">
      <h1>{translations[language].home.extra.titulo}</h1>
      <div className='contenedor'>
        <div className='extra left'>
          <img src={left} loading="lazy" alt='pinlet familia izquierda'></img>
        </div>
        <div className='extra mid'>
          <img src={mid} loading="lazy" alt='pinlet familia centro'></img>
        </div>
        <div className='extra right'>
          <img src={right} loading="lazy" alt='pinlet familia derecha'></img>
        </div>
      </div>
    </div>
  )
}